
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import PageStep from '@/components/layout/PageStep.vue'
import WizardStepBlockContainer from '@/components/pages/auth/wizard/WizardStepBlockContainer.vue'
import { wizardSteps } from '@/definitions/auth/data'

export default defineComponent({
  components: {
    PageStep,
    WizardStepBlockContainer,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(2)
    const route = useRoute()
    const country = computed(() => route.meta.country)

    const wizard = ref([
      {
        icon: 'tmi-phone-globe',
        title: 'Toll-free number',
        titleBadge: 'Registration Required',
        description: '8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator.',
        list: [
          'Tax ID (EIN number) <strong>not</strong> required',
          'Brand registration: <strong>Free</strong>',
          'Campaign registration: <strong>Free</strong>',
          'Number cost: <strong>$10 per month</strong>',
          'Traffic verified with the <strong>SMS aggregator</strong>',
          'Approval period <strong>up to 5 weeks</strong>',
          '2-way SMS, MMS and voice supported',
          'Minimum initial credit required: $20',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderSettings', params: { numberType: 'toll-free' } },
      },
      {
        icon: 'tmi-phone-geo',
        title: '10DLC number',
        titleBadge: 'Registration Required',
        description: '10-digit local U.S. numbers approved by the mobile network operators for sending texts.',
        list: [
          'Tax ID (EIN number) <strong>required</strong>',
          'Brand registration + vetting: <strong>$50 one time</strong>',
          'Campaign registration: <strong>$35 per quarter</strong>',
          'Number cost: <strong>$10 per month</strong>',
          'Traffic whitelisted with the <strong>mobile operators</strong>',
          'Approval period <strong>up to 5 weeks</strong>',
          '2-way SMS, MMS and voice supported',
          'Minimum initial credit required: $100',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderSettings', params: { numberType: 'local' } },
      },
    ])

    return {
      wizardSteps,
      currentIndex,
      wizard,
    }
  },
})
